import axios from "axios";
import React from "react";
import { getRequest } from "./QuoteApiService";

export const getAddress = async (id, reject) => {
    try {
      console.log("userServices > GetAddress called...");
      const res = await getRequest('GetAddress/' + id);
      console.log("GetAddress > axios res=", res);
      return res;
    }
    catch (err) {
      console.log("GetAddress > axios err=", err);
      reject("Error in GetAddress axios!");
      return err;
    }
}
